import {Component, inject, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {filter} from 'rxjs';
import {ActivityService} from './services/activity.service';
import {SharedService} from './services/shared.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private router = inject(Router);
  private shared = inject(SharedService);
  private activityService = inject(ActivityService);

  title = 'photo-booth';

  /**
   * All'onInit
   * Ascolto i cambiamenti di routing per aggiornare il servizio di activity
   * Aggiungo i listener
   */
  ngOnInit() {
    this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          console.log('Route changed:', event.url);
          if(event.url === '/' || event.url === this.shared.homeUrl) {
            this.activityService.removeListenerInterval();
          } else {
            this.activityService.addListenerInterval();
          }
          this.activityService.refreshInteractionTime();
        });
    this.activityService.addListeners();
  }

}
