import { Routes } from '@angular/router';
import {DefaultErrorComponent} from './core/default-error/default-error.component';

const hostName = window.location.host;

/**
 * Verifica se il dominio specificato è presente nel nome host corrente.
 * Considera anche la porta se presente nel nome host.
 *
 * @param {string} domain - Il dominio da verificare.
 * @returns {boolean} Ritorna true se il dominio corrisponde al nome host corrente, altrimenti false.
 */
const isOnDomain = (domain: string) => {
	return RegExp('(' + domain + ')|(' + domain + '):(\d+)?$').test(hostName);
};

/**
 * Seleziona la configurazione di routing in base al dominio corrente.
 *
 * Se il dominio corrente corrisponde a 'localhost', restituisce un array di oggetti che definiscono le rotte
 * per diversi percorsi, altrimenti restituisce una rotta di default per gestire gli errori.
 *
 * @returns {Array<object>} Un array di oggetti che definiscono le rotte, oppure un array con una singola rotta di default.
 */
const rootSelector: any = () => {
	if (isOnDomain('localhost')) {
		console.log('match localhost');
		return [
			{path: 'g-photobooth', pathMatch: 'prefix', loadChildren: () => import('./photo-booth/photo-booth.routes').then(m => m.routes)}
		];
	} else if (isOnDomain('giacapp.com')) {
		return [{path: 'g-photobooth', pathMatch: 'prefix', loadChildren: () => import('./photo-booth/photo-booth.routes').then(m => m.routes)}];
	} else if (isOnDomain('photobooth-dev-80cab.web.app')) { // TODO Rimuovere, provvisorio
		return [{path: 'g-photobooth', pathMatch: 'prefix', loadChildren: () => import('./photo-booth/photo-booth.routes').then(m => m.routes)}];
	} else {
		console.log('No match');
		return [{path: '', pathMatch: 'full', component: DefaultErrorComponent}];
	}
};

export const routes: Routes = [
	...rootSelector(),
	{path: '**', component: DefaultErrorComponent},
];
