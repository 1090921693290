import {inject, Injectable} from '@angular/core';
import {SharedService} from './shared.service';
import {ModalService} from './modal.service';

@Injectable({
	providedIn: 'root'
})
export class ActivityService {

	private sharedService = inject(SharedService);
	private modalService = inject(ModalService);

	lastInteractionTime: any;
	listenersFunId: any | undefined;

	constructor() {
	}

	/**
	 * Aggiungo i listener per calcolare il tempo di inattività
	 */
	addListeners() {
		this.lastInteractionTime = Date.now();

		window.addEventListener('mousemove', () => {
			this.lastInteractionTime = Date.now();
		});
		window.addEventListener('mousedown', () => {
			this.lastInteractionTime = Date.now();
		});
		window.addEventListener('mouseup', () => {
			this.lastInteractionTime = Date.now();
		});
		window.addEventListener('touchstart', () => {
			this.lastInteractionTime = Date.now();
		});
		window.addEventListener('touchmove', () => {
			this.lastInteractionTime = Date.now();
		});
	}

	/**
	 * Aggiorna il lastInteractionTime
	 */
	refreshInteractionTime(){
		this.lastInteractionTime = Date.now();
	}

	/**
	 * Aggiungo la funzione per controllare il tempo di inattività
	 */
	addListenerInterval(){
		if(this.listenersFunId){
			clearInterval(this.listenersFunId);
		}
		// Check ogni 5 secondi
		this.listenersFunId = setInterval(() => {
			const now = Date.now();
			// Se è passato troppo tempo chiudo tutte le modali e ritorno alla pagina iniziale
			if (now - this.lastInteractionTime > this.sharedService.inactivity) {
				this.modalService.closeQrCodeModal();
				this.modalService.closeLoadingModal();
				this.modalService.closeConfirmModal();
				window.location.reload();
			}
		}, 5000);
	}

	/**
	 * Rimuove il controllo sul last interaction
	 */
	removeListenerInterval(){
		if (this.listenersFunId) {
			clearInterval(this.listenersFunId);
			this.listenersFunId = undefined;
		}
	}

}
