import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SharedService {

	imgResult: string | undefined;
	deviceId: string | undefined;
	// height = 768;
	height = 720;
	width = 1280;
	// thumbnails: string[] = [];
	frameSelected = "/assets/frame_giacapp1.png";
	timer = 10;
	inactivity = 60000;
	serverTooLong = 60000;
	facingMode: string | undefined;
	questions: string[] = [];
	themeSelected: number = 0;

	printEmail: string = '';
	settingsCode: string = '';

	experienceStarted = false;
	codeInserted = false;

	swapAvailable = false;

	userConsent = false; // Variabile che viene messa a true se l'utente viene riconosciuto
	geventsData: {uid: string, eventId: string} | null = null;

	homeUrl: string = '/';

	orientation: 'landscape' | 'portrait' = 'landscape';
	cbs: {id: string, cb: any}[] = [];

	deviceType: 'desktop' | 'mobile' | 'tablet' | 'unknown' = 'desktop';
	constructor() {
	}

	/**
	 * Converte un base64Url in base64
	 * @param url
	 */
	fromBase64UrlToBase64(url: string){
		return url.replace('data:image/jpeg;base64,', '');
	}

	/**
	 * Aggiungo la callback
	 * @param id
	 * @param cb
	 */
	addCallback(id: string, cb: any){
		this.cbs.push({id, cb});
	}

	/**
	 * Rimuovo la callback
	 * @param id
	 */
	removeCallback(id: string){
		const i = this.cbs.findIndex(x => x.id === id);
		if(i > -1){
			this.cbs.splice(i, 1);
		}
	}

	/**
	 * Esegue la callback
	 * @param id
	 */
	executeCallback(id: string){
		const i = this.cbs.findIndex(x => x.id === id);
		if(i > -1){
			this.cbs[i].cb();
		}
	}

}
