import pkg from '../../package.json';

export const environment = {
  appVersion: pkg.version+'-dev',
  production: false,
  socket: {
    port: 9430,
    token: '9b1b6c66-65b5-4569-b8dc-dda1c7116cc3',
    host: 'https://server-photo-booth.giacapp.com'
  },
  firebaseConfig: {
    apiKey: "AIzaSyA_YHd5_RQz_5xApvCupETJ0AVy95hPKT0",
    authDomain: "photobooth-dev-80cab.firebaseapp.com",
    projectId: "photobooth-dev-80cab",
    storageBucket: "photobooth-dev-80cab.appspot.com",
    appId: "1:505579531401:web:02514ccad49680cd292a58"
  },
  URL_API_GEvents: 'https://europe-west3-gevents-dev.cloudfunctions.net'
};
